/* Hero */
.defaultHero,
.roomsHero {
  min-height: calc(100vh - var(--nav-height));
  background: url("../images/defaultBcg.jpeg") center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.roomsHero {
  background-image: url("../images/room-2.jpeg");
  min-height: 60vh;
}